/* custome font */
@font-face {
  font-family: MochiyPopOne-Regular;
  src: url("./Assets/Fonts/MochiyPopOne-Regular.ttf");
}
@font-face {
  font-family: NotoSansJP-700;
  src: url("./Assets/Fonts/NotoSansJP-Bold.otf");
}
@font-face {
  font-family: NotoSansJP-300;
  src: url("./Assets/Fonts/NotoSansJP-Light.otf");
}
@font-face {
  font-family: NotoSansJP-500;
  src: url("./Assets/Fonts/NotoSansJP-Medium.otf");
}
@font-face {
  font-family: NotoSansJP-400;
  src: url("./Assets/Fonts/NotoSansJP-Regular.otf");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
.App {
  text-align: center;
  font-family: NotoSansJP-400;
  font-size: 1.4rem;
  /* padding-bottom: 2rem; */
  position:fixed;
  top:0;
  width:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
[contenteditable] {
  outline: 0px solid transparent;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* mui css override */
.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Custom scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* custom mui text */
.MuiTypography-root {
  font-size: 1.4rem !important;
}
/* calendar mui */
.MuiPickersCalendarHeader-dayLabel {
  font-size: 1.4rem !important;
}
.MuiPickersSlideTransition-transitionContainer {
  font-size: 1.4rem !important;
}
.rbc-off-range-bg {
  background: transparent;
}
.rbc-selected-cell {
  background: #d4d4d4;
}
.MuiMenuItem-root {
  font-size: 1.4rem !important;
  font-family: "NotoSansJP-500";
}

/* custom style react-date-picker */
.rdrDateDisplayWrapper{
  display: none;
}
.MuiMenu-list{
  display: flex;
  flex-direction: column;
}
/* Modal */
.MuiBackdrop-root{
  background-color: rgb(136 136 136 / 50%) !important;
}
/* message not found */
.messageNotFound{
  font-size: 2rem;
}
.rdrDayPassive
  .rdrDayNumber{
    display: none !important;

  }

  .MuiModal-root > .MuiPopover-paper {
    max-height: 40rem !important;
  }

.backIcon-container {
  position:absolute;
  left:10px;
}

.backIcon {
  width:4.5rem;
  height:4.5rem;
  cursor: pointer;
}

.downloadIcon {
  width:2.5rem;
  height:2.5rem;
  cursor: pointer;
}

.notificationIcon {
  width:4.5rem;
  height:4.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ff6a0c;
}

.logoutIcon {
  width:2rem;
  height:2rem;
  margin-right:5px;

}

.approvalIcon {
  width:1.5rem;
  height:1.5rem;
  object-fit:contain;
}

.inputMonth {
  padding: 1rem;
  border-radius: 24px;
  color:#464646;
  background-color:#F2F2F2;
  border: 1px solid #AEAEAF;
  font-size: 1.5rem;
  font-family: NotoSansJP-500;

  @media (max-width: 980px) {
      margin-bottom:1rem;
      width:100%;
  }
}

.absolute_top_pixel {
  position: absolute;
  top: 0;
  height: 1px;
  width: 1px;
  background: transparent;
}
.fixed_top_pixel {
  position: fixed;
  top: 0;
  height: 1px;
  width: 1px;
  background: transparent;
}

.authInput {
  border: 1px solid #bbb;
  background-color: #F2F2F2;
  border-radius: 50px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 30rem;
  font-family: NotoSansJP-400;
  height: 4rem;
  min-height: 40px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: rgb(70, 70, 70);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
}